// ** MUI Imports
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const FooterContent = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-end',
                justifyContent: 'space-between'
            }}>
            <Typography sx={{ mr: 2 }}>
                {`© ${new Date().getFullYear()}, Made with `}
                <Box component="span" sx={{ color: 'error.main' }}>
                    ❤️
                </Box>
                {` by `}
                <Link target="_blank" href="https://ideasinn.com">
                    Ideas Inn.
                </Link>
            </Typography>
        </Box>
    );
};

export default FooterContent;
