import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Box, TextField } from '@mui/material';

import { setMessage } from '../actions/message';
import siteService from '../services/crudService';
import metaService from 'src/services/metaService';

const API_ENDPOINT = 'admin/site';

export default function DepartmentForm(props) {
    const mode = props?.data?.id ? 'update' : 'create';
    const dispatch = useDispatch();

    const { handleClose } = props;

    const [loading, setLoading] = useState(false);
    const [site, setSite] = useState({
        name: props.data ? props.data.name : '',
        address: props.data ? props.data.address : ''
    });

    const { name, address } = site;

    const handleOnSubmit = (ev) => {
        ev.preventDefault();

        const values = [name, address];

        const allFieldsFilled = values.every((field) => {
            const value = `${field}`.trim();
            return value !== '' && value !== '0';
        });

        if (!allFieldsFilled) {
            return dispatch(
                setMessage({
                    severity: 'error',
                    content: 'Please fill out all the required fields.'
                })
            );
        }

        setLoading(true);

        if (mode === 'update') {
            return siteService
                .update(API_ENDPOINT, props?.data?.id, site)
                .then((res) => {
                    setLoading(false);
                    handleClose();
                    metaService.updateMeta(true, ['site']);
                    dispatch(
                        setMessage({
                            content: 'Site updated successfully',
                            data: res.data,
                            callback: () => window.location.reload()
                        })
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(
                        setMessage({
                            severity: 'error',
                            content: 'Something went wrong',
                            data: err.response.data
                        })
                    );
                });
        }

        return siteService
            .create(API_ENDPOINT, site)
            .then((res) => {
                setLoading(false);
                handleClose();
                metaService.updateMeta(true, ['site']);
                dispatch(
                    setMessage({
                        content: 'Site added successfully',
                        data: res.data,
                        callback: () => window.location.reload()
                    })
                );
            })
            .catch((err) => {
                setLoading(false);
                dispatch(
                    setMessage({
                        severity: 'error',
                        content: 'Something went wrong',
                        data: err.response.data
                    })
                );
            });
    };

    const handleInputChange = (ev) => {
        const { name, value } = ev.target;
        setSite((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Box component="form" onSubmit={handleOnSubmit} noValidate>
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="Name"
                        autoFocus
                        value={name}
                    />
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="address"
                        label="Address"
                        name="address"
                        autoComplete="address"
                        autoFocus
                        value={address}
                    />
                    <Button
                        disabled={loading}
                        type="submit"
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        {props.title || 'Create Site'}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}
