import React from 'react';
import { Avatar } from '@mui/material';
import { strToColor } from 'src/utils';

export default function UserAvatar({ photo, name, size, sx = {} }) {
    const getIntials = (name) => {
        const [firstName, lastName] = name.split(' ');
        return lastName ? firstName.charAt(0) + lastName.charAt(0) : firstName.charAt(0);
    };

    if (photo) {
        return <Avatar size={size || 32} alt={name} src={photo} />;
    }

    return (
        <Avatar
            sx={{
                bgcolor: strToColor(name),
                color: 'white',
                width: size || 32,
                height: size || 32,
                fontSize: 14,
                textTransform: 'uppercase',
                ...sx
            }}>
            {getIntials(name)}
        </Avatar>
    );
}
