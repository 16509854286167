import React from 'react';
import metaService from 'src/services/metaService';
import { valueFormatter } from 'src/utils';

import DataGridWrapper from './DataGridWrapper';
import MaintenanceTypeForm from './MaintenanceTypeForm';

const maintenanceTypes = metaService.getSection('maintenance-type');

const API_ENDPOINT = 'admin/maintenance/type';

export default function MaintenanceTypeList() {
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'label',
            headerName: 'Label',
            flex: 0.4
        },
        {
            field: 'parent',
            headerName: 'Parent',
            flex: 0.2,
            valueGetter: (params) => valueFormatter(params.row.parent ? [params.row.parent] : [], maintenanceTypes)
        },
        {
            field: 'requires_confirmation',
            headerName: 'Requires Confirmation',
            flex: 0.2
        },
        {
            field: 'requires_machine',
            headerName: 'Requires Machine',
            flex: 0.2,
        }
    ];

    return (
        <DataGridWrapper
            endpoint={API_ENDPOINT}
            columns={columns}
            entitySingle="Maintenance Type"
            entityMultiple="Maintenance Types"
            Form={MaintenanceTypeForm}
        />
    );
}
