// ** MUI Imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

// ** Icons Imports
import DotsVertical from 'mdi-material-ui/DotsVertical';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import ScoreOutlinedIcon from '@mui/icons-material/ScoreOutlined';

const salesData = [
    {
        stats: '71',
        title: 'Total',
        color: 'primary',
        icon: <ScoreOutlinedIcon sx={{ fontSize: '1.75rem' }} />
    },
    {
        stats: '6',
        title: 'Resolved',
        color: 'success',
        icon: <DoneAllOutlinedIcon sx={{ fontSize: '1.75rem' }} />
    },
    {
        stats: '4',
        color: 'warning',
        title: 'In Progress',
        icon: <RotateLeftOutlinedIcon sx={{ fontSize: '1.75rem' }} />
    },
    {
        stats: '61',
        color: 'info',
        title: 'Pending',
        icon: <PendingOutlinedIcon sx={{ fontSize: '1.75rem' }} />
    }
];

const Tickets = () => {
    return salesData.map((item, index) => (
        <Grid item xs={12} sm={3} key={index}>
            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                    variant="rounded"
                    sx={{
                        mr: 3,
                        width: 44,
                        height: 44,
                        boxShadow: 3,
                        color: 'common.white',
                        backgroundColor: `${item.color}.main`
                    }}>
                    {item.icon}
                </Avatar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="caption">{item.title}</Typography>
                    <Typography variant="h6">{item.stats}</Typography>
                </Box>
            </Box>
        </Grid>
    ));
};

const TicketsCard = () => {
    return (
        <Card>
            <CardHeader
                title="Work Requests"
                action={
                    <IconButton
                        size="small"
                        aria-label="settings"
                        className="card-more-options"
                        sx={{ color: 'text.secondary' }}>
                        <DotsVertical />
                    </IconButton>
                }
                subheader={
                    <Typography variant="body2">
                        <Box component="span" sx={{ fontWeight: 600, color: 'text.primary' }}>
                            Total 13.5% decrease
                        </Box>{' '}
                        this month
                    </Typography>
                }
                titleTypographyProps={{
                    sx: {
                        mb: 2.5,
                        lineHeight: '2rem !important',
                        letterSpacing: '0.15px !important'
                    }
                }}
            />
            <CardContent sx={{ pt: (theme) => `${theme.spacing(3)} !important` }}>
                <Grid container spacing={[5, 0]}>
                    {Tickets()}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default TicketsCard;
