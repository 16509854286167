import React from 'react';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

export default function FormDialog(props) {
    const { children, title, open, handleClose } = props;

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{React.cloneElement(children, { ...props })}</DialogContent>
        </Dialog>
    );
}
