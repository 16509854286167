import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MenuItem, FormControl, InputLabel, Button, Select, Box, TextField } from '@mui/material';

import { setMessage } from '../actions/message';
import metaService from '../services/metaService';
import deptService from '../services/crudService';

const API_ENDPOINT = 'admin/department';

const allSites = metaService.getSites();

const deptTypes = [
    { id: 1, name: 'OPR' },
    { id: 2, name: 'MNT' }
];

export default function DepartmentForm(props) {
    const mode = props.data?.id ? 'update' : 'create';
    const dispatch = useDispatch();
    const { handleClose } = props;

    const [loading, setLoading] = useState(false);
    const [department, setDepartment] = useState({
        name: props.data ? props.data.name : '',
        dept_code: props.data ? props.data.dept_code : '',
        dept_type: props.data ? props.data.dept_type : '',
        site: props.data ? props.data.site : ''
    });

    const { name, dept_type, dept_code, site } = department;

    const handleOnSubmit = (ev) => {
        ev.preventDefault();

        const values = [name, dept_type, site];

        const allFieldsFilled = values.every((field) => {
            const value = `${field}`.trim();
            return value !== '' && value !== '0';
        });

        if (!allFieldsFilled) {
            return dispatch(
                setMessage({
                    severity: 'error',
                    content: 'Please fill out all the required fields.'
                })
            );
        }

        setLoading(true);

        if (mode === 'update') {
            return deptService
                .update(API_ENDPOINT, props?.data?.id, department)
                .then((res) => {
                    setLoading(false);
                    handleClose();
                    metaService.updateMeta(true, ['department']);
                    dispatch(
                        setMessage({
                            content: 'Department updated successfully',
                            data: res.data,
                            callback: () => window.location.reload()
                        })
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(
                        setMessage({
                            severity: 'error',
                            content: 'Something went wrong',
                            data: err.response.data
                        })
                    );
                });
        }

        return deptService
            .create(API_ENDPOINT, department)
            .then((res) => {
                setLoading(false);
                handleClose();
                metaService.updateMeta(true, ['department']);
                dispatch(
                    setMessage({
                        content: 'Department added successfully',
                        data: res.data,
                        callback: () => window.location.reload()
                    })
                );
            })
            .catch((err) => {
                setLoading(false);
                dispatch(
                    setMessage({
                        severity: 'error',
                        content: 'Something went wrong',
                        data: err.response.data
                    })
                );
            });
    };

    const handleInputChange = (ev) => {
        const { name, value } = ev.target;
        setDepartment((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Box component="form" onSubmit={handleOnSubmit} noValidate>
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="Name"
                        autoFocus
                        value={name}
                    />
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="dept_code"
                        label="Code"
                        name="dept_code"
                        autoComplete="Code"
                        value={dept_code}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="site">Site</InputLabel>
                        <Select
                            labelId="site"
                            onChange={handleInputChange}
                            fullWidth
                            id="site"
                            label="Site"
                            name="site"
                            value={site}>
                            {allSites.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="dept_type">Department Type</InputLabel>
                        <Select
                            labelId="dept_type"
                            onChange={handleInputChange}
                            fullWidth
                            id="dept_type"
                            label="Department Type"
                            name="dept_type"
                            value={dept_type}>
                            {deptTypes.map(({ id, name }) => (
                                <MenuItem key={id} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        disabled={loading}
                        type="submit"
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        {props.title || 'Create Department'}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}
