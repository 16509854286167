import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, CircularProgress, Typography, Card, CardContent } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { valueFormatter } from '../../utils';
import statsService from '../../services/statsService';
import metaService from '../../services/metaService';

const API_ENDPOINT = 'user';

const allDepts = metaService.getDepartments();
const userGroups = metaService.getGroups();

export default function Users() {
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const columns = useMemo(
        () => [
            { field: 'id', headerName: 'ID', width: 50 },
            {
                field: 'name',
                headerName: 'Name',
                width: 150
            },
            {
                field: 'username',
                headerName: 'Username',
                width: 150
            },
            {
                field: 'groups',
                headerName: 'Groups',
                width: 150,
                valueGetter: (params) => valueFormatter(params.row.groups, userGroups)
            },
            {
                field: 'departments',
                headerName: 'Departments',
                width: 150,
                valueGetter: (params) => valueFormatter(params.row.departments, allDepts)
            },
            {
                field: 'count_unresolved',
                headerName: 'Unresolved Issues',
                width: 140
            },
            {
                field: 'hours_worked',
                headerName: 'Hours Worked',
                width: 140
            },
            {
                field: 'calls_attended',
                headerName: 'Calls Attended',
                width: 140
            }
        ],
        []
    );

    useEffect(() => {
        setLoading(true);

        statsService
            .all(API_ENDPOINT)
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    }, [location]);

    if (loading)
        return (
            <Box
                sx={{
                    height: '50vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                <CircularProgress color="primary" />
            </Box>
        );

    return data.length ? (
        <>
            <Typography variant="h5" component="h1" color="primary" mb={4}>
                Users Performance Report
            </Typography>
            <Card>
                <CardContent sx={{ height: 700, width: '100%' }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar }}
                    />
                </CardContent>
            </Card>
        </>
    ) : (
        'No report found!'
    );
}
