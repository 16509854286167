import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { valueFormatter } from '../../utils';
import statsService from '../../services/statsService';
import metaService from '../../services/metaService';

const API_ENDPOINT = 'machine';

const allDepts = metaService.getDepartments();
const allSites = metaService.getSites();

export default function Machines() {
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const columns = useMemo(
        () => [
            { field: 'id', headerName: 'ID', width: 50 },
            {
                field: 'name',
                headerName: 'Name',
                width: 150
            },
            {
                field: 'code',
                headerName: 'Code',
                width: 150
            },
            {
                field: 'site',
                headerName: 'Site',
                width: 150,
                valueGetter: (params) => valueFormatter([params.row.site], allSites)
            },
            {
                field: 'department',
                headerName: 'Department',
                width: 150,
                valueGetter: (params) => valueFormatter([params.row.department], allDepts)
            },
            {
                field: 'count_maint_requests',
                headerName: 'Maintenance Requests',
                width: 140
            },
            {
                field: 'count_open_requests',
                headerName: 'Open Requests',
                width: 140
            },
            {
                field: 'count_closed_requests',
                headerName: 'Closed Requests',
                width: 140
            },
            {
                field: 'count_maint_nonopr',
                headerName: 'Non-Operated Requests',
                width: 140
            }
        ],
        []
    );

    useEffect(() => {
        setLoading(true);

        statsService
            .all(API_ENDPOINT)
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    }, [location]);

    if (loading)
        return (
            <Box
                sx={{
                    height: '50vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                <CircularProgress color="primary" />
            </Box>
        );

    return data.length ? (
        <>
            <Typography variant="h5" component="h1" color="primary" mb={4}>
                Machines Status Report
            </Typography>
            <Card>
                <CardContent sx={{ height: 700, width: '100%' }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        checkboxSelection={false}
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar }}
                    />
                </CardContent>
            </Card>
        </>
    ) : (
        'No report found!'
    );
}
