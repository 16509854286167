/**
 *
 * @param {Array} values
 * @param {Array} source
 * @param {String} seperator
 * @returns {String}
 */
const valueFormatter = (values = [], source = [], seperator = ', ') => {
    if (!values.length) return '';

    return source
        .filter((obj) => values.indexOf(obj?.id) !== -1)
        .map((val) => val?.name || val?.label)
        .join(seperator);
};

/**
 *
 * @param {Array} arr
 * @param {Number} id
 * @returns {Object|Number}
 */
const renderLabel = (arr = [], id) => {
    const record = arr.find((item) => item.id === id);
    return record ? record?.name : id;
};

/**
 * Creates color hex from provided string.
 *
 * @param {String} string
 * @returns {String}
 */
const strToColor = (string) => {
    let hash = 0,
        i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
};

export { valueFormatter, renderLabel, strToColor };
