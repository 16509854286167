import { useEffect } from 'react';
import authService from 'src/services/authService';

export default function Logout() {
    useEffect(() => {
        authService.logout();
    });

    return null;
};
