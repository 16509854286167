import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
    Grid,
    Button,
    CircularProgress,
    styled,
    Typography,
    Card,
    CardContent
} from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import Swal from 'sweetalert2';

import crudService from '../services/crudService';
import FormDialog from './FormDialog';
import { setMessage } from '../actions/message';
import metaService from 'src/services/metaService';
import { usePermissions } from 'src/hooks/usePermissions';

const PageTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0)
}));

const PageSubTitle = styled(Typography)(({ theme }) => ({}));

function GridLoadingLayout({ children }) {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '80vh' }}>
            <Grid item xs={12}>
                <CircularProgress />
            </Grid>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    );
}

export default function DataGridWrapper(props) {
    /** Props */
    const {
        endpoint = '',
        columns = [],
        entitySingle = '',
        entityMultiple = '',
        description,
        gridOptions = {},
        Form
    } = props;

    /** Hooks */
    const location = useLocation();
    const dispatch = useDispatch();
    const entity = entitySingle.toLowerCase();
    const canCreate = usePermissions([`add_${entity}`]);
    const canUpdate = usePermissions([`change_${entity}`]);
    const canDelete = usePermissions([`delete_${entity}`]);

    /** State */
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [record, setRecord] = useState({});

    /** Popup form state  */
    const [title, setTitle] = useState('');
    const [open, setOpen] = useState(false);

    const toggleForm = useCallback(() => setOpen(!open), [open]);

    const createRecord = useCallback(() => {
        setTitle(`Add a New ${entitySingle}`);
        setRecord(null);
        toggleForm();
    }, [toggleForm, entitySingle]);

    const updateRecord = useCallback(
        ({ row }) => {
            setTitle(`Update ${entitySingle}: ${row.name || row.id}`);
            setRecord(row);
            toggleForm();
        },
        [entitySingle, toggleForm]
    );

    const deleteRecord = useCallback(
        async ({ id, name }) => {
            return await crudService
                .remove(endpoint, id)
                .then((res) => {
                    metaService.updateMeta(true);
                    dispatch(
                        setMessage({
                            content: `<b>${name}</b> ${entitySingle} with ID <b>${id}</b> deleted successfully!`,
                            callback: () => window.location.reload()
                        })
                    );
                })
                .catch((err) => {
                    dispatch(
                        setMessage({
                            severity: 'error',
                            content: `Oops, something went wrong!`
                        })
                    );
                });
        },
        [dispatch, endpoint, entitySingle]
    );

    const confirmDeletion = useCallback(
        (record) => {
            Swal.fire({
                icon: 'warning',
                html: `Are you sure you want to delete <b>${record.name}</b> ${entitySingle}?`,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            }).then((res) => {
                if (res.isConfirmed) {
                    deleteRecord(record);
                }
            });
        },
        [entitySingle, deleteRecord]
    );

    const columnsMemo = useMemo(
        () => [
            ...columns,
            {
                field: 'actions',
                type: 'actions',
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<EditOutlined />}
                        onClick={() => updateRecord(params)}
                        label="Edit"
                        showInMenu
                        disabled={!canUpdate}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteOutline />}
                        onClick={() => confirmDeletion(params.row)}
                        label="Delete"
                        showInMenu
                        disabled={!canDelete}
                    />
                ]
            }
        ],
        [columns, updateRecord, confirmDeletion, canUpdate, canDelete]
    );

    useEffect(() => {
        setLoading(true);
        crudService
            .list(endpoint)
            .then((res) => {
                if (res.data.length) setData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    }, [location, endpoint]);

    if (loading)
        return (
            <GridLoadingLayout>
                <Typography color="secondary">Loading, please wait...</Typography>
            </GridLoadingLayout>
        );

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <PageTitle variant="h4" component="h1">
                    {entityMultiple}
                </PageTitle>
                <PageSubTitle variant="body2" component="p">
                    {description ? description : 'List of ' + entityMultiple}
                </PageSubTitle>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardContent sx={{ height: 720, width: '100%' }}>
                        <DataGrid
                            rows={data}
                            columns={columnsMemo}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                            checkboxSelection={false}
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar }}
                            {...gridOptions}
                        />
                    </CardContent>
                </Card>
            </Grid>

            {Form && (
                <Grid item xs={12}>
                    <FormDialog open={open} title={title} data={record} handleClose={toggleForm}>
                        <Form />
                    </FormDialog>

                    {canCreate && <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={createRecord}>
                        {`Add a ${entitySingle}`}
                    </Button>}
                </Grid>
            )}
        </Grid>
    );
}
