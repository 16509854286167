export const EventTypes = [
    'CREATE',
    'MESSAGE',
    'CHANGE',
    'ASSIGN',
    'ACCEPT',
    'DECLINE',
    'START',
    'DEFECT:REPORT',
    'DEFECT:ACKNOWLEDGE',
    'PROVISION:REQUEST',
    'PROVISION:APPROVE',
    'PROVISION:AVAILABLE',
    'PROVISION:ACQUIRE',
    'OUTSOURCE:REQUEST',
    'OUTSOURCE:AVAILABLE',
    'OUTSOURCE:WORKSTART',
    'OUTSOURCE:WORKEND',
    'OUTSOURCE:RESOLVED',
    'OUTSOURCE:NOTRESOLVED',
    'OUTSOURCE:CLOSE',
    'RESOLVED',
    'NOTRESOLVED',
    'CLOSE',
    'TRANSFER',
];