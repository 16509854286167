import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MenuItem, FormControl, InputLabel, Button, Select, Box, TextField } from '@mui/material';

import { setMessage } from '../actions/message';
import machineService from '../services/crudService';
import metaService from '../services/metaService';

const machines = metaService.getSection('equipment-machine');

const API_ENDPOINT = 'admin/equipment/machine-part';

export default function MachinePartForm(props) {
    const mode = props?.data?.id ? 'update' : 'create';
    const dispatch = useDispatch();

    const { handleClose } = props;

    const [loading, setLoading] = useState(false);
    const [machinePart, setMachinePart] = useState({
        name: props.data ? props.data.name : '',
        code: props.data ? props.data.code : '',
        machine: props.data ? props.data.machine : ''
    });

    const { name, code, machine } = machinePart;

    const handleOnSubmit = (ev) => {
        ev.preventDefault();

        const values = [name, code, machine];

        const allFieldsFilled = values.every((field) => {
            const value = `${field}`.trim();
            return value !== '' && value !== '0';
        });

        if (!allFieldsFilled) {
            return dispatch(
                setMessage({
                    severity: 'error',
                    content: 'Please fill out all the required fields.'
                })
            );
        }

        setLoading(true);

        if (mode === 'update') {
            return machineService
                .update(API_ENDPOINT, props?.data?.id, machinePart)
                .then((res) => {
                    setLoading(false);
                    handleClose();
                    dispatch(
                        setMessage({
                            content: 'machinePart updated successfully',
                            data: res.data,
                            callback: () => window.location.reload()
                        })
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(
                        setMessage({
                            severity: 'error',
                            content: 'Something went wrong',
                            data: err.response.data
                        })
                    );
                });
        }

        return machineService
            .create(API_ENDPOINT, machinePart)
            .then((res) => {
                setLoading(false);
                handleClose();
                dispatch(
                    setMessage({
                        content: 'Machine part added successfully',
                        data: res.data,
                        callback: () => window.location.reload()
                    })
                );
            })
            .catch((err) => {
                setLoading(false);
                dispatch(
                    setMessage({
                        severity: 'error',
                        content: 'Something went wrong',
                        data: err.response.data
                    })
                );
            });
    };

    const handleInputChange = (ev) => {
        const { name, value } = ev.target;

        console.log(name, value)

        setMachinePart((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Box component="form" onSubmit={handleOnSubmit} noValidate>
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="code"
                        label="Code"
                        name="code"
                        autoComplete="Code"
                        autoFocus
                        value={code}
                    />
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="Name"
                        value={name}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="machine">Machine</InputLabel>
                        <Select
                            labelId="machine"
                            onChange={handleInputChange}
                            fullWidth
                            id="machine"
                            label="Machine"
                            name="machine"
                            value={machine}>
                            {machines.map(({ id, name, site }) => (
                                <MenuItem key={id} value={id}>
                                    {name} - Site {site}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        disabled={loading}
                        type="submit"
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        {props.title || 'Add Machine Part'}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}
