import { Container, Typography } from '@mui/material';
import React from 'react';
import Alert from '../Alert';

export default function PublicLayout({ children }) {
    return (
        <React.Fragment>
            <Alert />
            <Container component="main" maxWidth="xs">
                {children}
            </Container>
            <Container component="footer">
                <Typography variant="body1" textAlign="center">
                    &copy; All rights reserved.
                </Typography>
            </Container>
        </React.Fragment>
    );
}
