// ** Next Import
import { Link } from 'react-router-dom';

// ** MUI Imports
import { styled, useTheme, Box, Typography } from '@mui/material';

// ** Configs
import themeConfig from 'src/configs/themeConfig';

// ** Styled Components
const MenuHeaderWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4.5),
    transition: 'padding .25s ease-in-out',
    minHeight: theme.mixins.toolbar.minHeight
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    transition: 'opacity .25s ease-in-out, margin .25s ease-in-out'
}));

const StyledLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(3)
}));

const SiteLogo = styled('img')({
    maxWidth: 42
});

const VerticalNavHeader = (props) => {
    // ** Props
    const { verticalNavMenuBranding: userVerticalNavMenuBranding } = props;

    // ** Hooks
    const theme = useTheme();

    return (
        <MenuHeaderWrapper className="nav-header" sx={{ pl: 6 }}>
            {userVerticalNavMenuBranding ? (
                userVerticalNavMenuBranding(props)
            ) : (
                <StyledLink to="/">
                    <SiteLogo src={'/logo.png'} />
                    <HeaderTitle variant="h6" sx={{ ml: 3 }}>
                        {themeConfig.templateName}
                    </HeaderTitle>
                </StyledLink>
            )}
        </MenuHeaderWrapper>
    );
};

export default VerticalNavHeader;
