import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MenuItem, FormControl, InputLabel, Button, Select, Box, TextField } from '@mui/material';

import { setMessage } from '../actions/message';
import groupService from '../services/crudService';
import metaService from 'src/services/metaService';

const API_ENDPOINT = 'admin/group';

const generateHex = (length) => {
    let result = '';
    let hexChar = '0123456789abcdef';
    for (var i = 0; i <= length; i++) {
        result += hexChar.charAt(Math.floor(Math.random() * hexChar.length));
    }

    return result;
};

const deptTypes = [
    { id: 1, name: 'OPR' },
    { id: 2, name: 'MNT' }
];

export default function GroupForm(props) {
    const mode = props.data?.id ? 'update' : 'create';
    const dispatch = useDispatch();
    const { handleClose } = props;

    const [loading, setLoading] = useState(false);
    const [group, setGroup] = useState({
        name: props.data ? props.data.name : '',
        code: props.data ? props.data.code : generateHex(10),
        dept_type: props.data ? props.data.dept_type : '',
        power_level: props.data ? props.data.power_level : ''
    });

    const { name, code, dept_type, power_level } = group;

    const handleOnSubmit = (ev) => {
        ev.preventDefault();

        const values = [name, dept_type];

        const allFieldsFilled = values.every((field) => {
            const value = `${field}`.trim();
            return value !== '' && value !== '0';
        });

        if (!allFieldsFilled) {
            return dispatch(
                setMessage({
                    severity: 'error',
                    content: 'Please fill out all the required fields.'
                })
            );
        }

        setLoading(true);

        if (mode === 'update') {
            return groupService
                .update(API_ENDPOINT, props?.data?.id, group)
                .then((res) => {
                    setLoading(false);
                    handleClose();
                    metaService.updateMeta(true, ['group']);
                    dispatch(
                        setMessage({
                            content: 'Group updated successfully',
                            data: res.data,
                            callback: () => window.location.reload()
                        })
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(
                        setMessage({
                            severity: 'error',
                            content: 'Something went wrong',
                            data: err.response.data
                        })
                    );
                });
        }

        return groupService
            .create(API_ENDPOINT, group)
            .then((res) => {
                setLoading(false);
                handleClose();
                metaService.updateMeta(true, ['group']);
                dispatch(
                    setMessage({
                        content: 'Group added successfully',
                        data: res.data,
                        callback: () => window.location.reload()
                    })
                );
            })
            .catch((err) => {
                setLoading(false);
                dispatch(
                    setMessage({
                        severity: 'error',
                        content: 'Something went wrong',
                        data: err.response.data
                    })
                );
            });
    };

    const handleInputChange = (ev) => {
        const { name, value } = ev.target;
        setGroup((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Box component="form" onSubmit={handleOnSubmit} noValidate>
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="Name"
                        autoFocus
                        value={name}
                    />
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="code"
                        label="Code"
                        name="code"
                        autoComplete="Code"
                        value={code}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="power_level">Power Level</InputLabel>
                        <Select
                            labelId="power_level"
                            onChange={handleInputChange}
                            fullWidth
                            id="power_level"
                            label="Power Level"
                            name="power_level"
                            value={power_level}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id) => (
                                <MenuItem key={id} value={id}>
                                    {id}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="dept_type">Group Type</InputLabel>
                        <Select
                            labelId="dept_type"
                            onChange={handleInputChange}
                            fullWidth
                            id="dept_type"
                            label="Group Type"
                            name="dept_type"
                            value={dept_type}>
                            {deptTypes.map(({ id, name }) => (
                                <MenuItem key={id} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        disabled={loading}
                        type="submit"
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        {props.title || 'Create Group'}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}
