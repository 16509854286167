import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Typography, Paper, Card, Grid } from '@mui/material';

import metaService from '../services/metaService';
import TopPerformer from './dashboard/TopPerformer';
import Tickets from './dashboard/Tickets';
import MachinesOverview from './dashboard/Machines';
import SiteIssues from './dashboard/SiteIssues';
import DepartmentsOverview from './dashboard/Departments';
import Sites from './dashboard/Sites';

export default function Dashboard() {
    const location = useLocation();

    useEffect(() => {
        metaService.updateMeta();
    }, [location]);

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} md={4} p={2}>
                    <TopPerformer />
                </Grid>
                <Grid item xs={12} md={8} p={2}>
                    <Tickets />
                </Grid>
                <Grid item xs={12} md={4} p={2}>
                <Sites />
                </Grid>
                <Grid item xs={12} md={8} p={2}>
                    <SiteIssues />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
