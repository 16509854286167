import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    MenuItem,
    InputLabel,
    Button,
    Select,
    Box,
    TextField,
    Switch,
    FormControl,
    FormControlLabel
} from '@mui/material';

import { setMessage } from '../actions/message';
import maintenanceTypeService from '../services/crudService';
import metaService from 'src/services/metaService';

const API_ENDPOINT = 'admin/maintenance/type';
const maintenanceTypes = metaService.getSection('maintenance-type');

export default function MaintenanceTypeForm(props) {
    const mode = props?.data?.id ? 'update' : 'create';
    const dispatch = useDispatch();

    const { handleClose } = props;

    const [loading, setLoading] = useState(false);
    const [maintenanceType, setMaintenanceType] = useState({
        label: props.data ? props.data.label : '',
        parent: props.data ? props.data.parent : '',
        requires_confirmation: props.data ? props.data.requires_confirmation : false,
        requires_machine: props.data ? props.data.requires_machine : false
    });

    const { label, parent, requires_machine, requires_confirmation } = maintenanceType;

    const handleOnSubmit = (ev) => {
        ev.preventDefault();

        const values = [label, requires_machine, requires_confirmation];

        const allFieldsFilled = values.every((field) => {
            const value = `${field}`.trim();
            return value !== '' && value !== '0';
        });

        if (!allFieldsFilled) {
            return dispatch(
                setMessage({
                    severity: 'error',
                    content: 'Please fill out all the required fields.'
                })
            );
        }

        setLoading(true);

        if (mode === 'update') {
            return maintenanceTypeService
                .update(API_ENDPOINT, props?.data?.id, maintenanceType)
                .then((res) => {
                    setLoading(false);
                    handleClose();
                    dispatch(
                        setMessage({
                            content: 'Maintenance Type updated successfully',
                            data: res.data,
                            callback: () => window.location.reload()
                        })
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(
                        setMessage({
                            severity: 'error',
                            content: 'Something went wrong',
                            data: err.response.data
                        })
                    );
                });
        }

        return maintenanceTypeService
            .create(API_ENDPOINT, maintenanceType)
            .then((res) => {
                setLoading(false);
                handleClose();
                dispatch(
                    setMessage({
                        content: 'Maintenance Type added successfully',
                        data: res.data,
                        callback: () => window.location.reload()
                    })
                );
            })
            .catch((err) => {
                setLoading(false);
                dispatch(
                    setMessage({
                        severity: 'error',
                        content: 'Something went wrong',
                        data: err.response.data
                    })
                );
            });
    };

    const handleInputChange = (ev) => {
        const { name, value } = ev.target;

        switch (name) {
            case 'requires_machine':
            case 'requires_confirmation':
                setMaintenanceType((prevState) => ({
                    ...prevState,
                    [name]: ev.target.checked
                }));
                break;
            default:
                setMaintenanceType((prevState) => ({
                    ...prevState,
                    [name]: value
                }));
                break;
        }
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Box component="form" onSubmit={handleOnSubmit} noValidate>
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="label"
                        label="Label"
                        name="label"
                        autoComplete="Label"
                        autoFocus
                        value={label}
                    />
                    <FormControl fullWidth margin="normal">
                        <FormControlLabel
                            aria-label={'requires_confirmation'}
                            control={
                                <Switch
                                    name="requires_confirmation"
                                    onChange={handleInputChange}
                                    checked={requires_confirmation}
                                />
                            }
                            label="Requires Confirmation"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <FormControlLabel
                            control={
                                <Switch
                                    name="requires_machine"
                                    checked={requires_machine}
                                    onChange={handleInputChange}
                                />
                            }
                            label="Requires Machine"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="parent">Parent</InputLabel>
                        <Select
                            labelId="parent"
                            onChange={handleInputChange}
                            fullWidth
                            id="parent"
                            label="Parent"
                            name="parent"
                            value={parent}>
                            {maintenanceTypes
                                .filter((p) => p.id !== props?.data?.id)
                                .map(({ id, label }) => (
                                    <MenuItem key={id} value={id}>
                                        {label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <Button
                        disabled={loading}
                        type="submit"
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        {props.title || 'Add Maintenance Type'}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}
