import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePermissions } from 'src/hooks/usePermissions';

export default function PrivateRoute({ permissions, children }) {
    const isPermitted = usePermissions(permissions);
    const { isLoggedIn } = useSelector(({ auth }) => auth);

    if (!isLoggedIn) return <Navigate to="/login" />;

    return (isLoggedIn && isPermitted) ? children : <Navigate to="/" />;
}
