import Axios from 'axios';
import metaService from './metaService';

const API_URL = `${process.env.REACT_APP_API_URL}/user/auth/`;

const login = async (username, password) => {
    return Axios.post(API_URL + 'token/', { username, password }).then((response) => {
        if (response.data.token) {
            localStorage.setItem('user', JSON.stringify(response.data));
            metaService.updateMeta();
        }

        return response.data;
    });
};

const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('data-timestamp');
    metaService.removeMeta();
    window.location.reload();
};

const getToken = () => {
    return localStorage.getItem('token');
};

const authService = {
    login,
    logout,
    getToken
};

export default authService;
