import React from 'react';

import DataGridWrapper from './DataGridWrapper';
import MachinePartForm from './MachinePartForm';

import metaService from '../services/metaService';
import { valueFormatter } from '../utils';

const API_ENDPOINT = 'admin/equipment/machine-part';

const machines = metaService.getSection('equipment-machine');

export default function MachinePartList() {
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.1 },
        {
            field: 'code',
            headerName: 'Code',
            flex: 0.15
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.15
        },
        {
            field: 'machine',
            headerName: 'Machine',
            flex: 0.15,
            valueGetter: (params) => valueFormatter([params.row.machine], machines)
        },
        {
            field: 'created',
            headerName: 'Created',
            type: 'date',
            flex: 0.2
        },
        {
            field: 'updated',
            headerName: 'Updated',
            type: 'date',
            flex: 0.2
        }
    ];

    return (
        <DataGridWrapper
            endpoint={API_ENDPOINT}
            columns={columns}
            entitySingle="Machine Part"
            entityMultiple="Machine Parts"
            Form={MachinePartForm}
        />
    );
}
