import axios from 'axios';

import authHeader from './authHeader';

const API_URL = `${process.env.REACT_APP_API_URL}/`;

/**
 * Returns list of records for given entity.
 * @param {String} path
 * @param {Object} params
 * @returns {Promise}
 */
const list = (path, params) => {
    return axios.get(`${API_URL + path}/`, { headers: authHeader(), data: params });
};

/**
 * Returns single record for given entity.
 * @param {String} path
 * @param {Number} id
 * @returns {Promise}
 */
const get = (path, id) => {
    return axios.get(`${API_URL + path}/${id}/`, { headers: authHeader() });
};

/**
 * Creates a new record for given entity.
 * @param {String} path
 * @param {Object} data
 * @returns {Promise}
 */
const create = (path, data) => {
    return axios.post(`${API_URL + path}/`, { ...data }, { headers: authHeader() });
};

/**
 * Updates the record for given entity using its ID.
 * @param {String} path
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
const update = (path, id, data) => {
    return axios.patch(`${API_URL + path}/${id}/`, { ...data }, { headers: authHeader() });
};

/**
 * Deletes the record for given entity using its ID.
 * @param {String} path
 * @param {Number} id
 * @returns {Promise}
 */
const remove = (path, id) => {
    return axios.delete(`${API_URL + path}/${id}/`, { headers: authHeader() });
};

const crudService = { list, get, create, update, remove };

export default crudService;
