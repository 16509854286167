import Axios from 'axios';

import authHeader from './authHeader';

const allEndpoints = [
    'department',
    'site',
    'group',
    'permission',
    'equipment/machine',
    'maintenance/type'
];

const API_URL = `${process.env.REACT_APP_API_URL}/admin`;

/**
 * @returns {Promise}
 */
const updateMeta = async (forceUpdate = false, endpoints = allEndpoints) => {
    if (!isExpired() && !forceUpdate) return Promise.reject('Already up to date!');

    return Axios.all(
        endpoints.map((endpoint) =>
            Axios.get(`${API_URL}/${endpoint}/`, {
                headers: authHeader(),
                data: { section: endpoint.replace('/', '-') }
            })
        )
    ).then((res) =>
        res.map((r) => {
            const data = JSON.parse(r.config.data);

            setSection(data.section, r.data);
            setSection('data-timestamp', Date.now());

            return r;
        })
    );
};

/**
 * Checks data timestamp and compare agains current time to calculate expiry.
 * @returns {Boolean}
 */
const isExpired = () => {
    const dataStamp = Number(getSection('data-timestamp'));
    const dataDate = new Date(dataStamp * 1000);

    dataDate.setDate(dataDate.getDate() + 7);

    return Date.now() > dataDate.getTime();
};

/**
 * Removes meta stored in localStorage.
 * @returns void
 */
const removeMeta = () => {
    return allEndpoints.forEach((endpoint) => localStorage.removeItem(endpoint.replace('/', '-')));
};

/**
 * Get list of departments from localStorage.
 * @returns {Array}
 */
const getDepartments = () => {
    return JSON.parse(localStorage.getItem('department')) || [];
};

/**
 * Set list of company departments to localStorage.
 * @param {Array} departments
 */
const setDepartments = (departments) => {
    return localStorage.setItem('department', JSON.stringify(departments));
};

/**
 * Get list of user groups from localStorage.
 * @returns {Array}
 */
const getGroups = () => {
    return JSON.parse(localStorage.getItem('group')) || [];
};

/**
 * Set list of user groups to localStorage.
 * @param {Array} groups
 */
const setGroups = (groups) => {
    return localStorage.setItem('group', JSON.stringify(groups));
};

/**
 * Get list of company sites from localStorage.
 * @returns {Array}
 */
const getSites = () => {
    return JSON.parse(localStorage.getItem('site')) || [];
};

/**
 * Set list of company sites to localStorage.
 * @param {Array} sites
 */
const setSites = (sites) => {
    return localStorage.setItem('site', JSON.stringify(sites));
};

/**
 * Sets a section data to localStorage by name.
 * @param {String} name
 * @param {*} data
 */
const setSection = (name, data) => {
    localStorage.setItem(name, JSON.stringify(data));
};

/**
 * Get a section data from localStorage by name.
 * @param {String} name
 * @returns {*}
 */
const getSection = (name) => {
    return JSON.parse(localStorage.getItem(name));
};

const metaService = {
    removeMeta,
    updateMeta,
    getSites,
    setSites,
    getGroups,
    setGroups,
    getDepartments,
    setDepartments,
    setSection,
    getSection
};

export default metaService;
