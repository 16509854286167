import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

import { clearMessage } from '../actions/message';

export default function Alert() {
    const dispatch = useDispatch();
    const location = useLocation();

    const {
        message: { severity, content, data, callback, config = {} }
    } = useSelector((state) => state.message);

    useEffect(() => {
        content &&
            Swal.fire({
                ...config,
                title: severity === 'error' ? 'Oops!' : 'Yay!',
                html:
                    content +
                    ('object' === typeof data ? '<pre>' + JSON.stringify(data) + '</pre>' : ''),
                icon: severity || 'success'
            }).then((res) => {
                dispatch(clearMessage());
                callback && callback(res);
            });
    }, [dispatch, severity, content, data, callback, config]);

    useEffect(() => {
        dispatch(clearMessage());
    }, [location, dispatch]);

    return null;
}
