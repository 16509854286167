import React from 'react';

import DataGridWrapper from './DataGridWrapper';
import PresetMessageForm from './PresetMessageForm';

const API_ENDPOINT = 'maintenance/preset/message';

export default function PresetMessageList() {
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'event_type',
            headerName: 'Event Type',
            flex: 0.15
        },
        {
            field: 'language',
            headerName: 'language',
            flex: 0.15
        },
        {
            field: 'message',
            headerName: 'Message',
            flex: 0.45
        },
        {
            field: 'permission',
            headerName: 'Permission',
            flex: 0.1
        },
        {
            field: 'permission_codename',
            headerName: 'Permission Code Name',
            flex: 0.25
        }
    ];

    return (
        <DataGridWrapper
            endpoint={API_ENDPOINT}
            columns={columns}
            entitySingle="Preset Message"
            entityMultiple="Preset Messages"
            Form={PresetMessageForm}
        />
    );
}
