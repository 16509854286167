import React from 'react';

import DataGridWrapper from './DataGridWrapper';
import PermissionForm from './PermissionForm';

const API_ENDPOINT = 'admin/permission';

export default function PermissionList() {
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'codename',
            headerName: 'Code Name',
            flex: 0.5
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5
        },
    ];

    return (
        <DataGridWrapper
            endpoint={API_ENDPOINT}
            columns={columns}
            entitySingle="Permission"
            entityMultiple="Permissions"
        />
    );
}
