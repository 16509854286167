import React, { useState } from 'react';

// ** MUI Imports
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

// Styled component for the triangle shaped background image
const TriangleImg = styled('img')({
    right: 0,
    bottom: 0,
    height: 170,
    position: 'absolute'
});

// Styled component for the trophy image
const TrophyImg = styled('img')({
    right: 36,
    bottom: 20,
    height: 98,
    position: 'absolute'
});

const TopPerformer = () => {
    // ** Hook
    const theme = useTheme();
    const imageSrc = theme.palette.mode === 'light' ? 'triangle-light.png' : 'triangle-dark.png';

    const [loading, setLoading] = useState(false);

    useEffect(() => {}, []);

    return (
        <Card sx={{ position: 'relative' }}>
            <CardContent>
                <Typography variant="h6">Congratulations Mahir Choudhry!</Typography>
                <Typography variant="body2" sx={{ letterSpacing: '0.25px' }}>
                    Top performer of the month
                </Typography>
                <Typography variant="h6" sx={{ my: 4, color: 'primary.main' }}>
                    <b>12</b> Requests Resolved
                </Typography>
                <Button size="small" variant="contained">
                    View Performance
                </Button>
                <TriangleImg alt="triangle background" src={`/assets/${imageSrc}`} />
                <TrophyImg alt="trophy" src="/assets/trophy.png" />
            </CardContent>
        </Card>
    );
};

export default TopPerformer;
