import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { clearMessage } from '../actions/message';

const RouteCombiner = ({ PrivateRoute, routes, auth }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (['/login'].includes(location.pathname)) {
            dispatch(clearMessage());
        }
    }, [dispatch, location]);

    const RoutesMap = routes.map(
        ({
            Private,
            Permissions = [],
            exact = true,
            Layout = ({ children }) => <>{children}</>,
            modules,
            Component,
            path
        }) => {
            const ComponentWithLayout = () => {
                return (
                    <Layout settings={{ contentWidth: 'boxed' }}>
                        <Component />
                    </Layout>
                );
            };

            return Private
                ? [
                    <Route
                        path={path}
                        key={path}
                        exact={exact}
                        element={
                            <PrivateRoute auth permissions={Permissions}>
                                <ComponentWithLayout />
                            </PrivateRoute>
                        }
                        auth={auth}
                    />,
                    modules &&
                    modules.map((childrenProps) => (
                        <Route
                            key={childrenProps.path}
                            exact={childrenProps.exact}
                            element={
                                <PrivateRoute auth permissions={Permissions}>
                                    <Layout>
                                        <childrenProps.Component />
                                    </Layout>
                                </PrivateRoute>
                            }
                            path={path + childrenProps.path}
                        />
                    ))
                ]
                : [
                    <Route
                        key={path}
                        exact={exact}
                        element={<ComponentWithLayout />}
                        path={path}
                    />,
                    modules &&
                    modules.map((childrenProps) => (
                        <Route
                            key={childrenProps.path}
                            exact={childrenProps.exact}
                            element={
                                <Layout>
                                    <childrenProps.Component />
                                </Layout>
                            }
                            path={path + childrenProps.path}
                        />
                    ))
                ];
        }
    );

    return <Routes>{RoutesMap}</Routes>;
};

export default RouteCombiner;
