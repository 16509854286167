import { createTheme } from '@mui/material';

const FONT_FAMILY = ['Public Sans', 'Arial', 'sans-serif'].join(',');

const THEME = createTheme({
    palette: {},
    typography: {
        allVariants: {
            fontFamily: FONT_FAMILY
        }
    }
});

export { THEME, FONT_FAMILY };
