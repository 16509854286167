import axios from 'axios';

import authHeader from './authHeader';

const API_URL = `${process.env.REACT_APP_API_URL}/stats/`;

/**
 * @param {String} path
 * @returns {Promise}
 */
const all = (path, params) => {
    return axios.get(`${API_URL + path}/`, { params: params, headers: authHeader() });
};

/**
 * @param {String} path
 * @param {Number} id
 * @returns {Promise}
 */
const single = (path, id) => {
    return axios.get(`${API_URL + path}/${id}/`, { headers: authHeader() });
};

const statsService = { all, single };

export default statsService;
