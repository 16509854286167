import React from 'react';

import { valueFormatter } from '../utils';
import metaService from '../services/metaService';

import DepartmentForm from './DepartmentForm';
import DataGridWrapper from './DataGridWrapper';

const API_ENDPOINT = 'admin/department';

const allSites = metaService.getSites();

export default function DepartmentList() {
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.25
        },
        {
            field: 'dept_code',
            headerName: 'Code',
            flex: 0.25
        },
        {
            field: 'dept_type',
            headerName: 'Type',
            flex: 0.25
        },
        {
            field: 'site',
            headerName: 'Site',
            flex: 0.25,
            valueGetter: (params) => valueFormatter([params.row.site], allSites)
        }
    ];

    return (
        <DataGridWrapper
            endpoint={API_ENDPOINT}
            columns={columns}
            entitySingle="Department"
            entityMultiple="Departments"
            Form={DepartmentForm}
        />
    );
}
