import {
    HomeOutlined,
    AccountTreeOutlined,
    FactoryOutlined,
    PeopleOutline,
    ArticleOutlined,
    GroupsOutlined,
    MicrowaveOutlined,
    EngineeringOutlined,
    MessageOutlined,
    LockPersonOutlined,
    LogoutOutlined
} from '@mui/icons-material';

import VerticalLayout from 'src/@core/layouts/VerticalLayout';
import PublicLayout from '../components/layout/PublicLayout';
import NotFound from '../components/404';
import Dashboard from '../components/Dashboard';
import Login from '../components/Login';
import UserAll from '../components/User';
import DepartmentList from '../components/Department';
import SiteList from '../components/Site';
import GroupList from '../components/Group';
import UsersReport from '../components/reports/Users';
import MachinesReport from '../components/reports/Machines';
import MachineList from '../components/Machine';
import MachinePartList from '../components/MachinePart';
import MaintenanceTypeList from 'src/components/MaintenanceType';
import PresetMessageList from 'src/components/PresetMessage';
import PermissionList from 'src/components/Permission';
import Logout from 'src/components/Logout';

export const routes = [
    {
        path: '*',
        Component: NotFound,
        Layout: PublicLayout
    },
    {
        title: 'Dashboard',
        path: '/',
        Private: true,
        Component: Dashboard,
        Layout: VerticalLayout,
        Icon: HomeOutlined,
        Permissions: ['*'],
    },
    {
        sectionTitle: 'Content Management'
    },
    {
        path: '/login',
        Component: Login,
        Layout: PublicLayout
    },
    {
        title: 'Users',
        path: '/user',
        Component: UserAll,
        Layout: VerticalLayout,
        Private: true,
        Permissions: ['view_user'],
        Icon: PeopleOutline
    },
    {
        title: 'Sites',
        path: '/site',
        Component: SiteList,
        Layout: VerticalLayout,
        Private: true,
        Permissions: ['view_worksite'],
        Icon: FactoryOutlined
    },
    {
        title: 'Departments',
        path: '/department',
        Component: DepartmentList,
        Layout: VerticalLayout,
        Private: true,
        Permissions: ['view_department'],
        Icon: AccountTreeOutlined
    },
    {
        title: 'Groups',
        path: '/group',
        Component: GroupList,
        Layout: VerticalLayout,
        Private: true,
        Permissions: ['view_group'],
        Icon: GroupsOutlined
    },
    {
        title: 'Machines',
        path: '/machine',
        Component: MachineList,
        Layout: VerticalLayout,
        Private: true,
        Permissions: ['view_machine'],
        Icon: MicrowaveOutlined
    },
    // {
    //     title: 'Machine Parts',
    //     path: '/machine-part',
    //     Component: MachinePartList,
    //     Layout: VerticalLayout,
    //     Private: true,
    //     Permissions: ['view_machinepart'],
    //     Icon: SettingsOutlined
    // },
    {
        title: 'Maintenance Types',
        path: '/maintenance-type',
        Component: MaintenanceTypeList,
        Layout: VerticalLayout,
        Private: true,
        Permissions: ['view_maintenancetype'],
        Icon: EngineeringOutlined
    },
    {
        title: 'Preset Messages',
        path: '/preset-message',
        Component: PresetMessageList,
        Layout: VerticalLayout,
        Permissions: ['view_presetmessage'],
        Private: true,
        Icon: MessageOutlined
    },
    {
        title: 'Permissions',
        path: '/permission',
        Component: PermissionList,
        Layout: VerticalLayout,
        Permissions: ['view_permission'],
        Private: true,
        Icon: LockPersonOutlined
    },
    {
        sectionTitle: 'Reports',
        Permissions: ['view_report'],
    },
    {
        title: 'Users',
        path: '/report/users',
        Component: UsersReport,
        Layout: VerticalLayout,
        Private: true,
        Permissions: ['view_report'],
        Icon: ArticleOutlined
    },
    {
        title: 'Machines',
        path: '/report/machines',
        Component: MachinesReport,
        Layout: VerticalLayout,
        Private: true,
        Permissions: ['view_report'],
        Icon: ArticleOutlined
    },
    {
        sectionTitle: ' ',
        Permissions: ['*'],
    },
    {
        title: 'Logout',
        path: '/auth/logout',
        Component: Logout,
        Layout: VerticalLayout,
        Private: true,
        Permissions: ['*'],
        Icon: LogoutOutlined
    }
];
