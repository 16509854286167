import React from 'react';

import GroupForm from './GroupForm';
import DataGridWrapper from './DataGridWrapper';

const API_ENDPOINT = 'admin/group';

export default function GroupList() {
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.015 },
        { field: 'code', headerName: 'Code', flex: 0.15 },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.2
        },
        {
            field: 'dept_type',
            headerName: 'Type',
            flex: 0.2
        },
        { field: 'power_level', headerName: 'Power Level', flex: 0.1 }
    ];

    return (
        <DataGridWrapper
            endpoint={API_ENDPOINT}
            columns={columns}
            entitySingle="Group"
            entityMultiple="Groups"
            Form={GroupForm}
        />
    );
}
