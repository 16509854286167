import React from 'react';

import DataGridWrapper from './DataGridWrapper';
import MachineForm from './MachineForm';

import metaService from '../services/metaService';
import { valueFormatter } from '../utils';

const API_ENDPOINT = 'admin/equipment/machine';

const allDepts = metaService.getDepartments();
const allSites = metaService.getSites();

export default function MachineList() {
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'code',
            headerName: 'Code',
            width: 150
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150
        },
        {
            field: 'site',
            headerName: 'Site',
            width: 100,
            editable: false,
            valueGetter: (params) => valueFormatter([params.row.site], allSites)
        },
        {
            field: 'department',
            headerName: 'Department',
            sortable: false,
            width: 160,
            valueGetter: (params) => valueFormatter([params.row.department], allDepts)
        },
        // {
        //     field: 'parts',
        //     headerName: 'Parts',
        //     sortable: false,
        //     width: 160,
        //     valueGetter: (params) => JSON.stringify(params.row.parts)
        // },
        {
            field: 'created',
            headerName: 'Created',
            type: 'date',
            sortable: false,
            width: 160
        },
        {
            field: 'updated',
            headerName: 'Updated',
            type: 'date',
            sortable: false,
            width: 160
        }
    ];

    return (
        <DataGridWrapper
            endpoint={API_ENDPOINT}
            columns={columns}
            entitySingle="Machine"
            entityMultiple="Machines"
            Form={MachineForm}
        />
    );
}
