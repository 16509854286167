import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import {
    CircularProgress,
    Avatar,
    Button,
    TextField,
    Grid,
    Box,
    Typography,
    Link
} from '@mui/material';
import { LockClockOutlined } from '@mui/icons-material';

import { login } from '../actions/auth';
import { setMessage } from '../actions/message';
import metaService from 'src/services/metaService';

export default function Login() {
    const form = useRef();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(!1);
    const [loadingText, setLoadingText] = useState('Loading...');

    const { isLoggedIn } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        setLoadingText('Logging in...');

        if (!username.length || !password.length) {
            setLoading(false);
            return dispatch(
                setMessage({
                    content: 'All fields a required.',
                    severity: 'error'
                })
            );
        }

        dispatch(login(username, password))
            .then(() => {
                setLoading(true);
                setLoadingText('Syncing data...');
                return metaService.updateMeta(true)
                    .then(() => {
                        setLoading(false);
                        window.location.reload();
                    });
            })
            .then((res) => {
                setLoading(false);
                setLoadingText('');
            })
            .catch((err) => {
                dispatch(
                    setMessage({
                        content: err
                            ? JSON.stringify(err)
                            : 'Something went wrong. Please check your username and password.',
                        severity: 'error'
                    })
                );
                setLoading(false);
                setLoadingText('');
            });
    };

    if (!loading && isLoggedIn) {
        return <Navigate to="/" />;
    }

    if (loading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="secondary">{loadingText}</Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '90vh'
                }}>
                <Avatar sx={{ m: 1, bgcolor: 'primary.main', width: 50, height: 50 }}>
                    <LockClockOutlined style={{ color: 'white' }} />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }} ref={form}>
                    <TextField
                        onChange={onChangeUsername}
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        onChange={onChangePassword}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        disabled={loading}
                        type="submit"
                        fullWidth
                        color="primary"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        Sign In
                    </Button>
                    <Grid container textAlign="center">
                        <Grid item xs>
                            <Link href="/forgot-password" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </React.Fragment>
    );
}
