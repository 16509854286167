// ** Next Imports
import { Link, useLocation } from 'react-router-dom';

// ** MUI Imports
import {
    Chip,
    styled,
    ListItem,
    Typography,
    Box,
    ListItemIcon,
    ListItemButton
} from '@mui/material';

// ** Configs Import
import themeConfig from 'src/configs/themeConfig';

// ** Utils
import { handleURLQueries } from 'src/@core/layouts/utils';

// ** Styled Components
const MenuNavLink = styled(ListItemButton)(({ theme }) => ({
    width: '100%',
    textDecoration: 'none',
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    color: theme.palette.text.primary,
    padding: theme.spacing(2.25, 3.5),
    transition: 'opacity .25s ease-in-out',
    '&.active, &.active:hover': {
        boxShadow: theme.shadows[3],
        backgroundImage: `linear-gradient(98deg, ${theme.palette.customColors.primaryGradient}, ${theme.palette.primary.main} 94%)`
    },
    '&.active .MuiTypography-root, &.active .MuiSvgIcon-root': {
        color: `${theme.palette.common.white} !important`
    }
}));

const MenuItemTextMetaWrapper = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'opacity .25s ease-in-out',
    ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
});

const VerticalNavLink = ({ item, navVisible, toggleNavVisibility }) => {
    // ** Hooks
    const router = useLocation();
    const { Icon, title } = item;

    const isNavLinkActive = () => {
        if (router.pathname === item.path) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <ListItem
            disablePadding
            className="nav-link"
            disabled={item.disabled || false}
            sx={{ mt: 1.5, px: '0 !important' }}>
            <MenuNavLink
                component={Link}
                to={item.path === undefined ? '/' : `${item.path}`}
                className={isNavLinkActive() ? 'active' : ''}
                {...(item.openInNewTab ? { target: '_blank' } : null)}
                onClick={(e) => {
                    if (item.path === undefined) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    if (navVisible) {
                        toggleNavVisibility();
                    }
                }}
                sx={{
                    pl: 5.5,
                    ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' })
                }}>
                {Icon ? (
                    <Icon
                        sx={{
                            mr: 2.5,
                            color: 'text.primary',
                            transition: 'margin .25s ease-in-out'
                        }}
                    />
                ) : (
                    <ListItemIcon />
                )}

                <MenuItemTextMetaWrapper>
                    <Typography {...(themeConfig.menuTextTruncate && { noWrap: true })}>
                        {title}
                    </Typography>
                    {item.badgeContent ? (
                        <Chip
                            label={item.badgeContent}
                            color={item.badgeColor || 'primary'}
                            sx={{
                                height: 20,
                                fontWeight: 500,
                                marginLeft: 1.25,
                                '& .MuiChip-label': { px: 1.5, textTransform: 'capitalize' }
                            }}
                        />
                    ) : null}
                </MenuItemTextMetaWrapper>
            </MenuNavLink>
        </ListItem>
    );
};

export default VerticalNavLink;
