import React from 'react';
import { Link } from 'react-router-dom';
import { HomeRounded } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';

export default function NotFound() {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}>
            <Grid item xs={12}>
                <Typography variant="h1" color="primary">
                    Oops!
                </Typography>
                <Typography variant="h3">404 Not found.</Typography>
                <Button
                    component={Link}
                    to="/login"
                    startIcon={<HomeRounded />}
                    variant="outlined"
                    size="large"
                    sx={{ mt: 3 }}>
                    Take Me Home
                </Button>
            </Grid>
        </Grid>
    );
}
