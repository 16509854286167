import React from 'react';

import metaService from '../services/metaService';
import { valueFormatter } from '../utils';

import SiteForm from './SiteForm';
import DataGridWrapper from './DataGridWrapper';

const allDepts = metaService.getDepartments();

const API_ENDPOINT = 'admin/site';

export default function SiteList() {
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.05 },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.2,
            editable: false
        },
        {
            field: 'departments',
            headerName: 'Departments',
            flex: 0.4,
            editable: false,
            valueGetter: (params) => valueFormatter(params.row.departments, allDepts)
        },
        {
            field: 'address',
            headerName: 'Address',
            flex: 0.25,
            editable: false
        }
    ];

    return (
        <DataGridWrapper
            endpoint={API_ENDPOINT}
            columns={columns}
            entitySingle="Site"
            entityMultiple="Sites"
            Form={SiteForm}
        />
    );
}
