// ** Custom Menu Components
import { usePermissions } from 'src/hooks/usePermissions';
import VerticalNavLink from './VerticalNavLink';
import VerticalNavSectionTitle from './VerticalNavSectionTitle';

const resolveNavItemComponent = (item) => {
    if (item.sectionTitle) return VerticalNavSectionTitle;

    return VerticalNavLink;
};

const VerticalNavItems = (props) => {
    // ** Props
    const { verticalNavItems } = props;
    const isPermitted = usePermissions;

    const RenderMenuItems = verticalNavItems
        ?.filter(({ title, sectionTitle, Permissions }, idx) => {
            return !(title === undefined && sectionTitle === undefined) && isPermitted(Permissions) === true;
        })
        ?.map((item, index) => {
            const TagName = resolveNavItemComponent(item);

            return <TagName {...props} key={index} item={item} />;
        });

    return <>{RenderMenuItems}</>;
};

export default VerticalNavItems;
