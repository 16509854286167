import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MenuItem, FormControl, InputLabel, Button, Select, Box, TextField } from '@mui/material';

import { setMessage } from '../actions/message';
import { EventTypes } from 'src/utils/eventTypes';
import presetMessageService from '../services/crudService';
import metaService from 'src/services/metaService';

const API_ENDPOINT = 'admin/maintenance/preset/message';
const allPermissions = metaService.getSection('permission');

export default function PresetMessageForm(props) {
    const mode = props?.data?.id ? 'update' : 'create';
    const dispatch = useDispatch();

    const { handleClose } = props;

    const [loading, setLoading] = useState(false);
    const [presetMessage, setPresetMessage] = useState({
        event_type: props.data ? props.data.event_type : '',
        language: props.data ? props.data.language : '',
        message: props.data ? props.data.message : '',
        permission: props.data ? props.data.permission : '',
        permission_codename: props.data ? props.data.permission_codename : ''
    });

    const { event_type, language, message, permission } = presetMessage;

    const handleOnSubmit = (ev) => {
        ev.preventDefault();

        const values = [event_type, language, message, permission];

        const allFieldsFilled = values.every((field) => {
            const value = `${field}`.trim();
            return value !== '' && value !== '0';
        });

        if (!allFieldsFilled) {
            return dispatch(
                setMessage({
                    severity: 'error',
                    content: 'Please fill out all the required fields.'
                })
            );
        }

        setLoading(true);

        if (mode === 'update') {
            return presetMessageService
                .update(API_ENDPOINT, props?.data?.id, presetMessage)
                .then((res) => {
                    setLoading(false);
                    handleClose();
                    dispatch(
                        setMessage({
                            content: 'Preset Message updated successfully',
                            data: res.data,
                            callback: () => window.location.reload()
                        })
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(
                        setMessage({
                            severity: 'error',
                            content: 'Something went wrong',
                            data: err.response.data
                        })
                    );
                });
        }

        return presetMessageService
            .create(API_ENDPOINT, presetMessage)
            .then((res) => {
                setLoading(false);
                handleClose();
                dispatch(
                    setMessage({
                        content: 'Preset Message added successfully',
                        data: res.data,
                        callback: () => window.location.reload()
                    })
                );
            })
            .catch((err) => {
                setLoading(false);
                dispatch(
                    setMessage({
                        severity: 'error',
                        content: 'Something went wrong',
                        data: err.response.data
                    })
                );
            });
    };

    const handleInputChange = (ev) => {
        const { name, value } = ev.target;

        setPresetMessage((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Box component="form" onSubmit={handleOnSubmit} noValidate>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="event_type">Event Type</InputLabel>
                        <Select
                            labelId="event_type"
                            onChange={handleInputChange}
                            fullWidth
                            id="event_type"
                            label="Event Type"
                            name="event_type"
                            value={event_type}>
                            {EventTypes.map((event) => (
                                <MenuItem key={event} value={event}>
                                    {event}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="language">Language</InputLabel>
                        <Select
                            labelId="language"
                            onChange={handleInputChange}
                            fullWidth
                            id="language"
                            label="Language"
                            name="language"
                            value={language}>
                            {[
                                { id: 'eng', name: 'English' },
                                { id: 'urd', name: 'Urdu' }
                            ].map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        multiline
                        rows={3}
                        maxRows={4}
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="message"
                        label="Message"
                        name="message"
                        autoComplete="message"
                        value={message}
                        style={{ direction: language === 'urd' ? 'rtl' : 'ltr' }}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="permission">Permission</InputLabel>
                        <Select
                            labelId="permission"
                            onChange={handleInputChange}
                            fullWidth
                            id="permission"
                            label="Permission"
                            name="permission"
                            value={permission}>
                            {allPermissions.filter((p) => p.codename.indexOf('can_') !== -1 || p.codename.indexOf('is_role_') !== -1)
                                .map(({ id, name }) => (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <Button
                        disabled={loading}
                        type="submit"
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        {props.title || 'Add Preset Message'}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}
