import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';

import { SettingsConsumer, SettingsProvider } from './@core/context/settingsContext';
import ThemeComponent from './@core/theme/ThemeComponent';

import { THEME } from './theme';
import RouteCombiner from './routes/routeCombiner';
import { routes } from './routes/routes';
import PrivateRoute from './routes/PrivateRoute';

export default function App() {
    return (
        <SettingsProvider>
            <SettingsConsumer>
                {({ settings }) => {
                    return (
                        <ThemeComponent settings={settings}>
                            <CssBaseline />
                            <Router>
                                <RouteCombiner
                                    PrivateRoute={PrivateRoute}
                                    routes={routes}
                                    auth={true}
                                />
                            </Router>
                        </ThemeComponent>
                    );
                }}
            </SettingsConsumer>
        </SettingsProvider>
    );
}
